/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

/*
 Pour compiler les fichier CSS, compiler avec YARN
    Une seule fois : yarn encore dev
    En continu, recompilation automatique : yarn encore dev --watch
    En production : yarn encore production
 */

// start the Stimulus application
// import './bootstrap';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';


// import { Tooltip, Toast, Popover } from 'bootstrap';
const $ = require('jquery');

global.$ = global.jQuery = $;
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

// $(document).ready(function() {
//     $('[data-toggle="popover"]').popover();
// });

// loads the jquery package from node_modules
// var $ = require('jquery');

// import the function from greet.js (the .js extension is optional)
// ./ (or ../) means to look for a local file
// const greet = require('./js/greet');

// import greet from './js/greet';
